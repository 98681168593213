import { Guid } from '@/common/models/Guid';
import { ImageDataModel } from '@/common/models/ImageDataModel';
import { asNumber, asNumberOrDefault } from '@/common/utils/NumberFunctions';

import { PredictiveOptionPoints } from './PredictiveOptionPoints';

export class PredictiveQuestionOption {
  id: Guid;
  text: string = '';
  imageUrl: string;

  get Image() {
    return ImageDataModel.fromJsonOrUrl(this.imageUrl);
  }

  order: number;
  isCorrect: boolean = false;
  initialResponsePercentage?: number;
  points: PredictiveOptionPoints;

  constructor(props?: Partial<PredictiveQuestionOption>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.order = asNumber(props.order, 1);

    this.initialResponsePercentage = asNumberOrDefault(
      props.initialResponsePercentage,
      undefined
    );

    this.points = new PredictiveOptionPoints(props.points);
  }
}
