import isArray from 'lodash/isArray';

export interface Dictionary<T = any> {
  [key: string | number]: T;
}

/**
 * Maps a dictionary or undefined value to a new dictionary of a specific type.
 * Good to be used when you want to break an immutable object
 * @param model The dictionary value.
 * @param map The map for each item.
 */
export const mapDictionary = <T>(
  model: Dictionary<T> | Dictionary<any> | undefined,
  map: (value: T | any) => T = (x) => x as T
) => {
  const mapped: Dictionary<T> = {};
  if (model) {
    Object.keys(model as Dictionary<unknown>).forEach(
      (key) => (mapped[key] = map(model[key]))
    );
  }
  return mapped;
};

export const mapDictionaryToArray = <T, U>(
  model: Dictionary<T> | Dictionary<any> | undefined,
  map: (value: T | any) => U
) => {
  if (!model) {
    return [];
  }

  return Object.keys(model as Dictionary<unknown>).map((key) => {
    return map(model[key]);
  });
};

export const mapArrayToDictionary = <T, U>(
  array: T[] | undefined,
  keyFunc: (value: T, index?: number) => string,
  valueFunc: (value: T, index?: number) => U
): Dictionary<U> => {
  if (!array || !isArray(array) || array.length === 0) {
    return {};
  }

  return array.reduce((o, item, i) => {
    o[keyFunc(item, i)] = valueFunc(item, i);
    return o;
  }, {} as Dictionary<U>);
};

export const dictionaryLength = <T>(
  model: Dictionary<T> | Dictionary<any> | undefined
) => {
  if (!model) {
    return 0;
  }

  return Object.keys(model as Dictionary<unknown>).length;
};

/**
 * Check if any items in the dictionary pass a given predicate.
 * @param model The dictionary value.
 * @param predicate The predicate to check.
 */
export const someDictionary = <T>(
  model: Dictionary<T> | undefined,
  predicate: (value: T) => boolean
) => {
  if (model) {
    return Object.keys(model as Dictionary<unknown>).some((key) =>
      predicate(model[key])
    );
  }
  return false;
};

/**
 * Removes the item from dictionary and creates a new dictionary
 */
export const deleteFromDictionary = <T>(value: Dictionary<T>, key: string) => {
  const newValue: Dictionary<T> = {};
  Object.keys(value || {}).forEach((k) => {
    if (k !== key) {
      newValue[k] = value[k];
    }
  });
  return newValue;
};

/**
 * Reduce the dictionary to a single value
 */
export const reduceDictionary = <T, U>(
  model: Dictionary<T> | undefined,
  reduceFunc: (previousValue: U, currentValue: T) => U,
  initialValue: U
): U => {
  if (!model) {
    return initialValue;
  }

  return Object.keys(model as Dictionary<unknown>).reduce(
    (previousValue, key) => {
      return reduceFunc(previousValue, model[key]);
    },
    initialValue
  );
};
