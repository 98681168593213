import { GetCardDescriptorButtonOptions } from '@/common/models/CardDescriptorProperties';
import { Guid } from '@/common/models/Guid';
import { ImageDataModel } from '@/common/models/ImageDataModel';
import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { SiteCardProperties } from '@/common/models/SiteCardProperties';
import { mapArray } from '@/common/utils/ArrayFunctions';
import { asBoolean } from '@/common/utils/BooleanFunctions';

import { ISiteResolveButton } from '../../site/ISiteResolveButton';

export class PickerProperties implements SiteCardProperties {
  BackgroundImageJson?: string;

  get BackgroundImage() {
    return ImageDataModel.fromJsonOrUrl(this.BackgroundImageJson);
  }

  OptionDefaultImageJson?: string;

  get OptionDefaultImage() {
    return ImageDataModel.fromJsonOrUrl(this.OptionDefaultImageJson);
  }

  PickerScreenTitle?: string;
  ProgressEnabled: boolean;
  ItemLabelsEnabled: boolean;
  SlotDefaultImageJson?: string;

  get SlotDefaultImage() {
    return ImageDataModel.fromJsonOrUrl(this.SlotDefaultImageJson);
  }

  ModalHeaderColor: string = '#ffffff';
  ModalHeaderBackgroundColor: string = '#000000';
  ModalHeaderImageJson?: string;

  get ModalHeaderImage() {
    return ImageDataModel.fromJsonOrUrl(this.ModalHeaderImageJson);
  }

  ModalBackgroundImageJson?: string;

  get ModalBackgroundImage() {
    return ImageDataModel.fromJsonOrUrl(this.ModalBackgroundImageJson);
  }

  ModalBackgroundColor: string = '#ffffff';
  ModalConfirmButtonText?: string;
  RandomizeButtonText?: string;
  RandomizeButtonEnabled?: boolean;
  ModalTextColor: string = '#000000';

  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;
  RandomizeButtonBackgroundColor?: string;
  RandomizeButtonTextColor?: string;
  RandomizeButtonBorderColor?: string;

  OptionSearchPlaceholder?: string = 'Search';

  OptionContentSectionsJson?: string;
  PickerOptionStatisticsJson?: string;

  ResultsLiveDataAutoPopup: boolean;

  ResultsLiveDataEnabled: boolean;
  ResultsLiveDataButtonText?: string;
  ResultsLiveDataButtonColor?: string;
  ResultsLiveDataButtonBackgroundColor?: string;
  ResultsLiveDataStageJson?: string;
  static DefaultResultsLiveDataButtonText = 'View results';
  static DefaultStageSize = {
    maxWidth: 1080,
    maxHeight: 1920
  };

  /**
   * Indicates if the Cover image should be shown over the option image
   * in the option search modal.
   */
  OptionSearchPreferCover?: boolean;

  getResultsLiveDataButton(
    site: ISiteResolveButton,
    properties: SitePageProperties,
    options?: GetCardDescriptorButtonOptions
  ) {
    return site.resolveButton({
      properties,
      color: this.ResultsLiveDataButtonColor || options?.fallbackColor,
      backgroundColor:
        this.ResultsLiveDataButtonBackgroundColor ||
        options?.fallbackBackgroundColor,
      text:
        this.ResultsLiveDataButtonText ||
        options?.fallbackText ||
        PickerProperties.DefaultResultsLiveDataButtonText
    });
  }

  constructor(props: Partial<PickerProperties> = {}) {
    Object.assign(this, props);
    this.ProgressEnabled = asBoolean(props.ProgressEnabled, true);
    this.ItemLabelsEnabled = asBoolean(props.ItemLabelsEnabled, true);
    this.ResultsLiveDataEnabled = asBoolean(
      props.ResultsLiveDataEnabled,
      false
    );
    this.ResultsLiveDataAutoPopup = asBoolean(
      props.ResultsLiveDataAutoPopup,
      false
    );
    this.OptionSearchPreferCover = asBoolean(
      props.OptionSearchPreferCover,
      false
    );
    this.RandomizeButtonEnabled = asBoolean(props.RandomizeButtonEnabled);
  }

  getOptionContentSections() {
    const defaultOrder = Object.keys(PickerOptionSectionTypes).map(
      (v, i) =>
        new PickerOptionSection({
          order: i,
          contentType: v as PickerOptionSectionTypes
        })
    );
    if (!this.OptionContentSectionsJson) return defaultOrder;
    try {
      const contentOrder = mapArray(
        JSON.parse(this.OptionContentSectionsJson),
        (x) => new PickerOptionSection(x)
      );
      return contentOrder.sort((a, b) => a.order - b.order);
    } catch {
      return defaultOrder;
    }
  }

  setOptionContentSections(model: PickerOptionSection[]) {
    this.OptionContentSectionsJson = JSON.stringify(model);
  }

  getPickerOptionStatistics() {
    if (!this.PickerOptionStatisticsJson) return [];
    try {
      const contentOrder = mapArray(
        JSON.parse(this.PickerOptionStatisticsJson),
        (x) => new PickerOptionStatistic(x)
      );
      return contentOrder.sort((a, b) => a.order - b.order);
    } catch {
      return [];
    }
  }

  setPickerOptionStatistics(model: PickerOptionStatistic[]) {
    this.PickerOptionStatisticsJson = JSON.stringify(model);
  }
}
export enum PickerOptionSectionTypes {
  Stats = 'Stats',
  Video = 'Video',
  Description = 'Description'
}

export class PickerOptionSection {
  order: number;
  contentType: PickerOptionSectionTypes;
  enabled?: boolean;
  title?: string;

  constructor(props: Partial<PickerOptionSection> = {}) {
    Object.assign(this, props);
    this.enabled = asBoolean(props.enabled);
  }
}

export class PickerOptionStatistic {
  id: Guid;
  order: number;
  title: string;

  constructor(props: Partial<PickerOptionStatistic> = {}) {
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.order = props.order ?? 0;
  }
}
