import { rgb } from 'color';

import { GetCardDescriptorButtonOptions } from '@/common/models/CardDescriptorProperties';
import { ImageDataModel } from '@/common/models/ImageDataModel';
import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { SiteCardProperties } from '@/common/models/SiteCardProperties';
import { asBoolean } from '@/common/utils/BooleanFunctions';
import { asNumber } from '@/common/utils/NumberFunctions';

import { ISiteResolveButton } from '../../site/ISiteResolveButton';
import {
  MemoryChallengeScoreConfig,
  MemoryMatchPointsBreakdown
} from './MemoryChallengeScoreConfig';

const black = '#000000';
const white = '#FFFFFF';

export class MemoryChallengeProperties implements SiteCardProperties {
  static DefaultLeaderboardButtonText = 'View leaderboard';
  ScoreConfigJson: string;

  get ScoreConfig(): MemoryChallengeScoreConfig {
    return this.LegacyScoring
      ? MemoryChallengeScoreConfig.legacy()
      : MemoryChallengeScoreConfig.fromJsonOrDefault(this.ScoreConfigJson);
  }

  HeaderBackgroundColor: string = black;
  HeaderTextColor: string = white;
  HeaderImage?: string;

  get HeaderImageModel(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.HeaderImage);
  }

  TimerTextColor: string = white;
  TimerBackgroundColor: string = rgb(black).alpha(0.1).toString();

  ScoringTextColor: string = white;
  ScoringBackgroundColor: string = black;
  ScoringProgressBarDisabled: boolean;
  ScoringProgressBarColor: string;
  LegacyScoring: boolean;

  ResultStatsColor: string = black;
  ResultStatsTextColor: string = white;
  LeaderboardEnabled: boolean;
  LeaderboardButtonText?: string;
  LeaderboardButtonColor?: string;
  LeaderboardButtonBackgroundColor?: string;
  LeaderboardJson: string;

  GameplayDescriptionText?: string;
  DescriptionColor: string = black;
  SecondsGameplay: number;

  get MillisecondsGameplay() {
    return this.SecondsGameplay * 1000;
  }

  BackTileImageJson?: string;

  get BackTileImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.BackTileImageJson);
  }

  BackgroundImageJson?: string;

  get BackgroundImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.BackgroundImageJson);
  }

  BackgroundColor: string = white;
  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;

  ConfettiEnabled: boolean;

  ResultDescriptionText: string;
  ResultTitleText: string = MemoryChallengeProperties.ResultTitleTextDefault;
  static ResultTitleTextDefault = 'Congratulations!';
  static DescriptionTextDefault = 'Description...';
  ResultPlayAgainDisabled: boolean;

  FailureDescriptionText: string;
  FailureTitleText: string = MemoryChallengeProperties.FailureTitleTextDefault;
  static FailureTitleTextDefault = 'Unlucky!';
  FailurePlayAgainDisabled: boolean;

  InstructionsButtonText: string;
  InstructionsButtonTextColor: string;
  InstructionsButtonBackgroundColor: string;
  InstructionsHeaderText: string;
  InstructionsHeaderColor: string = white;
  InstructionsDescriptionText: string;
  InstructionsDescriptionColor: string = white;
  static InstructionsButtonPlaceholder = 'Start';

  constructor(props?: Partial<MemoryChallengeProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.SecondsGameplay = asNumber(props.SecondsGameplay, 60);
    this.ConfettiEnabled = asBoolean(props.ConfettiEnabled, true);
    this.LegacyScoring = asBoolean(props.LegacyScoring);
    this.ScoringProgressBarDisabled = asBoolean(
      props.ScoringProgressBarDisabled
    );
    this.LeaderboardEnabled = asBoolean(props.LeaderboardEnabled, false);
    this.ResultPlayAgainDisabled = asBoolean(props.ResultPlayAgainDisabled);
    this.FailurePlayAgainDisabled = asBoolean(props.FailurePlayAgainDisabled);
  }

  getLeaderboardButton(
    site: ISiteResolveButton,
    properties: SitePageProperties,
    options?: GetCardDescriptorButtonOptions
  ) {
    return site.resolveButton({
      properties,
      color: this.LeaderboardButtonColor || options?.fallbackColor,
      backgroundColor:
        this.LeaderboardButtonBackgroundColor ||
        options?.fallbackBackgroundColor,
      text:
        this.LeaderboardButtonText ||
        options.fallbackText ||
        MemoryChallengeProperties.DefaultLeaderboardButtonText
    });
  }

  getMatchScore(streak: number): [number, MemoryMatchPointsBreakdown] {
    if (this.LegacyScoring) return [0, { match: 0, streak: 0 }];
    return this.ScoreConfig.calculateMatch(streak);
  }
}
