import type { PickerGameplaySlotOptionSelection } from 'common/models/picker/shared/PickerGameplaySlotOptionSelection';

import { Guid } from '@/common/models/Guid';
import { HeightWidth } from '@/common/models/HeightWidth';
import { ImageDataModel } from '@/common/models/ImageDataModel';
import { mapArray, tryRemoveFromArray } from '@/common/utils/ArrayFunctions';
import { asNumber, asNumberOrUndefined } from '@/common/utils/NumberFunctions';
import { getClampedSize } from '@/common/utils/SizeFunctions';

import { FrontPicker } from '../Front/FrontPicker';
import { IPicker } from './IPicker';
import { PickerSlot } from './PickerSlot';

export class PickerLayout {
  id: Guid;
  order: number;
  slots: PickerSlot[];
  properties: PickerLayoutProperties;

  get requiredSlots() {
    return this.slots.filter((x) => x.properties.Required);
  }

  get hasBackgroundImage() {
    return !!this.properties.BackgroundImage;
  }

  set name(v) {
    this.properties.Name = v;
  }

  get name() {
    return this.properties.Name;
  }

  get adminName() {
    return this.properties.Name || 'Untitled';
  }

  get isValid() {
    return this.slots.length > 0;
  }

  get labelDefaults() {
    return {
      labelTruncation: this.properties.LabelTruncationType,
      labelHeight: this.properties.labelHeight,
      labelWidth: this.properties.labelWidth,
      labelAlignment: this.properties.labelAlignment,
      labelPosition: this.properties.LabelPosition,
      backgroundColor: this.properties.labelBackgroundColor,
      color: this.properties.labelColor
    };
  }

  constructor(props: Partial<PickerLayout> = {}) {
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.slots = mapArray(props.slots, (x) => new PickerSlot(x));
    this.order = asNumber(props.order);
    this.properties = new PickerLayoutProperties(props.properties);
  }

  // stageWidth can't be passed based off a stageRef object, when it's assigned an update won't be triggered
  adminSlotsToCanvasItems(card: IPicker, stageWidth: number = 400) {
    return this.slots.map((x) => {
      const mapped = this.mapSlotForCanvas(x, card, stageWidth);
      mapped.name = x.adminName;
      return mapped;
    }, this);
  }

  // stageWidth can't be passed based off a stageRef object, when it's assigned an update won't be triggered
  frontSlotsToCanvasItems(
    card: FrontPicker,
    selections: PickerGameplaySlotOptionSelection[] = [],
    stageWidth = 400
  ) {
    return this.slots.map((x) => {
      const mapped = this.mapSlotForCanvas(x, card, stageWidth);
      const option = selections.find((s) => s.slotId.equals(mapped.id))?.option;
      if (!option) {
        return mapped;
      }

      mapped.name = option.name;
      const newImage = option.hasImage
        ? option.image
        : card.hasOptionDefaultImage
          ? card.optionDefaultImage
          : undefined;

      if (!!newImage) {
        const slotWidth = mapped.attrs.width;
        const slotHeight = mapped.attrs.height;
        mapped.setImage(newImage);
        const size = mapped.getCalculatedSize(card, stageWidth);
        mapped.updateAttrs({
          width: size.width,
          height: size.height
        });

        // centre selection image if it has been resized
        if (mapped.attrs.width < slotWidth) {
          mapped.updateAttrs({
            x: mapped.attrs.x + (slotWidth - mapped.attrs.width) / 2
          });
        }
        if (mapped.attrs.height < slotHeight) {
          mapped.updateAttrs({
            y: mapped.attrs.y + (slotHeight - mapped.attrs.height) / 2
          });
        }
      }

      mapped.updateAttrs({ labelEnabled: card.properties.ItemLabelsEnabled });
      return mapped;
    }, this);
  }

  addSlot() {
    this.slots.push(new PickerSlot());
  }

  deleteSlot(id: Guid) {
    const newSlots = [...this.slots];
    tryRemoveFromArray(newSlots, (x) => x.id.equals(id));
    this.slots = newSlots;
  }

  getBackgroundImage(fallback: ImageDataModel) {
    return this.hasBackgroundImage ? this.properties.BackgroundImage : fallback;
  }

  setBackgroundImage(value: ImageDataModel) {
    if (!value) {
      this.properties.BackgroundImageJson = undefined;
      return;
    }
    this.properties.BackgroundImageJson = value.toString();
  }

  getBackgroundImageAspectRatio(fallback: number) {
    if (!this.hasBackgroundImage) return fallback;

    return (
      this.properties.BackgroundImage.height /
      this.properties.BackgroundImage.width
    );
  }

  mapSlotForCanvas(slot: PickerSlot, card: IPicker, stageWidth: number) {
    const defaultImage = card.slotDefaultImage;
    const mapped = new PickerSlot(slot);
    const { height, width } = mapped.getCalculatedSize(card, stageWidth);
    mapped.updateAttrs({
      width,
      height
    });
    if (!mapped.hasImage) {
      mapped.setImage(defaultImage);
    }

    mapped.updateAttrs({ labelEnabled: card.properties.ItemLabelsEnabled });
    if (!!this.properties.LabelsFontSize) {
      mapped.updateAttrs({ fontSize: this.properties.LabelsFontSize });
    }
    return mapped;
  }

  getCreationImageReadSize(card: IPicker) {
    const DeviceDensity = 2.3;
    const { height, width } = this.getBackgroundImage(card.backgroundImage);
    // We want to double the size, for higher pixel devices
    return getClampedSize({
      size: { width: width * DeviceDensity, height: height * DeviceDensity },
      max: 1920
    });
  }

  public createNewSlot(stageSize: HeightWidth, fallbackAspectRatio: number) {
    const slotWidth = stageSize.width / 8;
    const slotHeight =
      slotWidth * this.getBackgroundImageAspectRatio(fallbackAspectRatio);
    const slot = new PickerSlot();
    slot.updateAttrs({
      x: stageSize.width / 2 - slotWidth / 2,
      y: stageSize.height / 2 - slotHeight / 2,
      stageSize
    });
    return slot;
  }
}

export class PickerLayoutProperties {
  Name: string;
  BackgroundImageJson: string;

  get BackgroundImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.BackgroundImageJson);
  }

  LabelsFontSize?: number;
  LabelTruncationType?: PickerLabelTruncationTypes =
    PickerLabelTruncationTypes.None;
  LabelPosition?: PickerLabelPosition = PickerLabelPosition.Bottom;
  labelBackgroundColor?: string;
  labelColor?: string;
  labelWidth?: number;
  labelHeight?: number = 20;
  labelAlignment?: PickerLabelAlignment = PickerLabelAlignment.Center;

  constructor(props?: Partial<PickerLayoutProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.LabelsFontSize = asNumberOrUndefined(props?.LabelsFontSize);
    this.labelHeight = asNumberOrUndefined(props?.labelHeight);
    this.labelWidth = asNumberOrUndefined(props?.labelWidth);
  }
}

export enum PickerLabelTruncationTypes {
  None = 'None',
  FirstWord = 'FirstWord',
  LastWord = 'LastWord'
}

export enum PickerLabelPosition {
  Top = 'Top',
  Right = 'Right',
  Bottom = 'Bottom',
  Left = 'Left'
}

export enum PickerLabelAlignment {
  Right = 'right',
  Center = 'center',
  Left = 'left'
}
