import { immerable } from 'immer';

import { CardTypes } from '@/common/models/CardTypes';
import { Guid } from '@/common/models/Guid';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';

import { LiveSurveyProperties } from '../shared/LiveSurveyProperties';

export class AdminLiveSurvey implements SiteCard {
  [immerable] = true;
  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.LiveSurvey;
  properties: LiveSurveyProperties;

  constructor(props?: Partial<AdminLiveSurvey>) {
    props = props || {};
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
    this.properties = new LiveSurveyProperties(props?.properties);
  }

  headerCoverImagePath() {
    return `site/${this.siteId}/card/${this.id}/header-cover`;
  }

  headerLogoPath() {
    return `site/${this.siteId}/card/${this.id}/header-logo`;
  }

  bodyBackgroundImagePath() {
    return `site/${this.siteId}/card/${this.id}/body-background`;
  }
}
