import { Guid } from '@/common/models/Guid';

import { QuizAnswerStyles } from './QuizAnswerStyles';
import { QuizQuestion } from './QuizQuestion';
import { QuizQuestionOption } from './QuizQuestionOption';

export const createQuizQuestion = (
  quizId: Guid,
  style: QuizAnswerStyles = QuizAnswerStyles.Single,
  order: number = 1
) => {
  return new QuizQuestion({
    id: Guid.newGuid(),
    quizId,
    style,
    order,
    options: [
      createQuizQuestionOption(1, true),
      createQuizQuestionOption(2, false)
    ]
  });
};

export const createQuizQuestionOption = (
  order: number = 1,
  isCorrect: boolean = false
) =>
  new QuizQuestionOption({
    id: Guid.newGuid(),
    text: '',
    imageUrl: '',
    order,
    isCorrect
  });
