import { ButtonDataModel } from '@/common/models/ButtonDataModel';
import { CardResult } from '@/common/models/card-result/CardResult';
import { CardTypes } from '@/common/models/CardTypes';
import { Guid } from '@/common/models/Guid';
import { ImageDataModel } from '@/common/models/ImageDataModel';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';
import { asBoolean } from '@/common/utils/BooleanFunctions';

import { disperseWheelWedgesForFront } from '../shared/_helpers';
import { SpinToWinProperties, WheelWedge } from '../shared/SpinToWinProperties';

export class AdminSpinToWin implements SiteCard {
  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.SpinToWin;
  properties: SpinToWinProperties;

  constructor(partial?: Partial<AdminSpinToWin>) {
    partial = partial || {};
    Object.assign(this, partial);
    SiteCardHelper.applyDefaults(this, partial);
    this.properties = new SpinToWinProperties(partial?.properties);
  }

  get WedgesForWheel() {
    return disperseWheelWedgesForFront(this.properties.WheelWedges);
  }

  getModalBackgroundImagePath() {
    return `site/${this.siteId}/card/${this.id}/modal-background`;
  }

  getSpinnerImagePath() {
    return `site/${this.siteId}/card/${this.id}/spinner-image`;
  }

  getWedgeData(results: CardResult[]): WedgeDataWithResult[] {
    const wedgeData = this.properties.WheelWedges;
    if (!results?.length || !wedgeData || !wedgeData.length) {
      return [];
    }

    return results.map((r) => {
      const wedge = wedgeData.find((w) => w.resultId.equals(r.id));
      const resultData = r.properties.getProbability();
      const viewProperties = r.properties.getLegacyContent();
      return new WedgeDataWithResult({
        ...wedge,
        name: r.name,
        isUnlimited: resultData.isUnlimited,
        recipients: r.recipients,
        resultTitle: viewProperties.Title,
        resultDescription: viewProperties.Description,
        resultDescriptionEditorStateJson:
          viewProperties.DescriptionLexicalStateJson,
        resultImage: viewProperties.Image,
        maxWinners: resultData.maxWinners,
        winProbabilityNumerator: resultData.winProbabilityNumerator,
        winProbabilityDenominator: resultData.winProbabilityDenominator,
        isFallback: resultData.isFallback,
        buttons: viewProperties.Buttons || [],
        prizeId: r.prizeId,
        confettiEnabled: r.properties.ConfettiEnabled
      });
    });
  }
}

export class WedgeDataWithResult extends WheelWedge {
  name: string;
  isUnlimited: boolean = true;
  recipients: number = 0;
  resultTitle: string;
  resultDescription: string;
  resultDescriptionEditorStateJson: string;
  resultImage?: ImageDataModel;
  confettiEnabled: boolean;
  winProbabilityNumerator: number;
  winProbabilityDenominator: number;
  maxWinners: number;
  isFallback: boolean = false;
  buttons?: ButtonDataModel[];
  prizeId?: Guid;
  constructor(props: Partial<WedgeDataWithResult> = {}) {
    super();
    Object.assign(this, props);
    this.confettiEnabled = asBoolean(props.confettiEnabled, true);
  }
}
