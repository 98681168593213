import { Guid } from '@/common/models/Guid';
import { ImageDataModel } from '@/common/models/ImageDataModel';
import { asString } from '@/common/utils/StringFunctions';

export class ChecklistOutcome {
  id: Guid;
  heading: string;
  text: string = '';
  imageUrl: string = '';

  get image(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.imageUrl);
  }

  minimumPoints: number = 0;
  maximumPoints: number = 1;
  fixed: boolean = false;

  constructor(props?: Partial<ChecklistOutcome>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.heading = asString(props.heading);
    this.text = asString(props.text);
  }
}
