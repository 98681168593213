import { CardTypes } from '@/common/models/CardTypes';
import { Guid } from '@/common/models/Guid';
import { ImageDataModel } from '@/common/models/ImageDataModel';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';
import { mapArray } from '@/common/utils/ArrayFunctions';
import { orderByAscending } from '@/common/utils/SortingFunctions';

import { ChecklistKinds } from './ChecklistKinds';
import { ChecklistOption } from './ChecklistOption';
import { ChecklistOutcome } from './ChecklistOutcome';
import { ChecklistProperties } from './ChecklistProperties';

export class Checklist implements SiteCard {
  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.Checklist;
  name: string;
  properties: ChecklistProperties;
  imageUrl: string;

  get image(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.imageUrl);
  }

  heading: string;
  kind: ChecklistKinds = ChecklistKinds.Text;
  options: ChecklistOption[] = [];
  outcomes: ChecklistOutcome[] = [];

  constructor(props?: Partial<Checklist>) {
    props = props || {};
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
    this.properties = new ChecklistProperties(props.properties);
    this.options = mapArray(props?.options, (x) => new ChecklistOption(x)).sort(
      orderByAscending
    );
    this.outcomes = mapArray(
      props?.outcomes,
      (x) => new ChecklistOutcome(x)
    ).sort((a, b) => a.maximumPoints - b.maximumPoints);
  }
}
