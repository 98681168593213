import { ButtonDataModel } from '@/common/models/ButtonDataModel';
import { ImageDataModel } from '@/common/models/ImageDataModel';
import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { SiteCardProperties } from '@/common/models/SiteCardProperties';
import { asBoolean } from '@/common/utils/BooleanFunctions';
import {
  getPrimaryBackgroundColor,
  getPrimaryTextColor
} from '@/common/utils/ButtonFunctions';
import { asNumber } from '@/common/utils/NumberFunctions';

import { ScratchAndWinScreenProps } from './types';

export interface InstantWinModalStyleProps {
  modalBackgroundColor: string;
  modalBackgroundImage: ImageDataModel;
  headerBackgroundColor: string;
  headerColor: string;
  brandImage: ImageDataModel;
  modalTitle: string;
  modalTitleEnabled: boolean;
}

export class ScratchAndWinProperties implements SiteCardProperties {
  //SiteCardProperties
  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;
  //end

  readonly DefaultCoverButtonText = 'SCRATCH AND WIN';
  CoverButtonText: string = this.DefaultCoverButtonText;
  CoverImageUrl?: string;
  get CoverImage() {
    return ImageDataModel.fromJsonOrUrl(this.CoverImageUrl);
  }
  CoverTitle?: string;
  CoverTitleEnabled: boolean;
  CoverButtonEnabled: boolean;

  ScratchTitle?: string;
  ScratchDescription?: string;
  ScratchImageUrl?: string;

  get ScratchImage() {
    const image = ImageDataModel.fromJsonOrUrl(this.ScratchImageUrl);
    if (image?.url) {
      image.setQuality(100);
    }

    return image;
  }

  MinimumScratchToComplete: number;
  readonly DefaultMinimumScratchToComplete = 60;

  // scratch and win front modal
  BrandImageUrl?: string;

  get BrandImage() {
    return ImageDataModel.fromJsonOrUrl(this.BrandImageUrl);
  }

  HeaderBackgroundColor: string = '#000000';
  BackgroundColor?: string = '#f1f3f5';
  BackgroundImageUrl?: string;

  get BackgroundImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.BackgroundImageUrl);
  }

  getBackgroundImage(properties: SitePageProperties): ImageDataModel {
    if (!this.BackgroundImageEnabled) return undefined;
    return this.BackgroundImage || properties.BackgroundImageModel;
  }

  BackgroundImageEnabled: boolean;
  TextColor: string = '#000000';
  HeaderColor: string = '#ffffff';
  ActiveTabColor: string = '#000000';
  ButtonText: string;
  ModalTitle?: string;
  ModalTitleEnabled: boolean;

  readonly DefaultBackToHubButtonText = 'Back to hub';
  BackToHubButtonText: string = this.DefaultBackToHubButtonText;
  BackToHubButtonTextColor: string;
  BackToHubButtonBackgroundColor: string;
  BackToHubButtonBorderColor: string;
  BackToHubButtonEnabled: boolean;

  readonly DefaultPlayAgainButtonText = 'Play again';
  PlayAgainButtonText: string = this.DefaultPlayAgainButtonText;
  PlayAgainButtonTextColor: string;
  PlayAgainButtonBorderColor: string;
  PlayAgainButtonBackgroundColor: string;
  PlayAgainButtonDisabled: boolean;

  constructor(props: Partial<ScratchAndWinProperties> = {}) {
    Object.assign(this, props);
    this.MinimumScratchToComplete = asNumber(
      props.MinimumScratchToComplete,
      this.DefaultMinimumScratchToComplete
    );
    this.BackgroundImageEnabled = asBoolean(props.BackgroundImageEnabled);
    this.ModalTitleEnabled = asBoolean(props.ModalTitleEnabled, true);
    this.CoverTitleEnabled = asBoolean(props.CoverTitleEnabled, true);
    this.CoverButtonEnabled = asBoolean(props.CoverButtonEnabled, true);
    this.BackToHubButtonEnabled = asBoolean(props.BackToHubButtonEnabled, true);
    this.PlayAgainButtonDisabled = asBoolean(
      props.PlayAgainButtonDisabled,
      false
    );
  }

  getScratchScreenProps(): ScratchAndWinScreenProps {
    return {
      description: this.ScratchDescription,
      title: this.ScratchTitle,
      image: this.ScratchImage
    };
  }

  getButtonTextColor(properties: SitePageProperties) {
    return getPrimaryTextColor({
      overrideColor: this.ButtonTextColor,
      properties
    });
  }

  getButtonBackgroundColor(properties: SitePageProperties) {
    return getPrimaryBackgroundColor({
      overrideColor: this.ButtonBackgroundColor,
      properties
    });
  }

  getModalTitle() {
    return this.ModalTitle || this.CoverTitle;
  }

  getModalBackgroundColor(properties: SitePageProperties) {
    return this.BackgroundColor || properties.BackgroundColor || '#ffffff';
  }

  getModalStyleProps(
    properties: SitePageProperties
  ): InstantWinModalStyleProps {
    return {
      brandImage: this.BrandImage,
      headerBackgroundColor: this.HeaderBackgroundColor,
      headerColor: this.HeaderColor,
      modalBackgroundColor: this.getModalBackgroundColor(properties),
      modalBackgroundImage: this.getBackgroundImage(properties),
      modalTitle: this.getModalTitle(),
      modalTitleEnabled: this.ModalTitleEnabled
    };
  }

  getModalBackgroundProps(properties: SitePageProperties) {
    return {
      image: this.getBackgroundImage(properties),
      color: this.getModalBackgroundColor(properties)
    };
  }

  getPlayAgainButtonBackgroundColor(properties: SitePageProperties) {
    return getPrimaryBackgroundColor({
      overrideColor: this.PlayAgainButtonBackgroundColor,
      properties
    });
  }

  getPlayAgainButtonTextColor(properties: SitePageProperties) {
    return getPrimaryTextColor({
      overrideColor: this.PlayAgainButtonTextColor,
      properties
    });
  }

  getBackToHubButtonBackgroundColor(properties: SitePageProperties) {
    return getPrimaryBackgroundColor({
      overrideColor: this.BackToHubButtonBackgroundColor,
      properties
    });
  }

  getBackToHubButtonTextColor(properties: SitePageProperties) {
    return getPrimaryTextColor({
      overrideColor: this.BackToHubButtonTextColor,
      properties
    });
  }

  getBackToHubButton(properties: SitePageProperties) {
    const bgColor = this.getBackToHubButtonBackgroundColor(properties);
    return new ButtonDataModel({
      backgroundColor: bgColor,
      color: this.getBackToHubButtonTextColor(properties),
      borderColor: this.BackToHubButtonBorderColor || bgColor,
      text: this.BackToHubButtonText
    });
  }

  getPlayAgainButton(properties: SitePageProperties) {
    const bgColor = this.getPlayAgainButtonBackgroundColor(properties);

    return new ButtonDataModel({
      backgroundColor: bgColor,
      color: this.getPlayAgainButtonTextColor(properties),
      borderColor: this.PlayAgainButtonBorderColor || bgColor,
      text: this.PlayAgainButtonText
    });
  }
}
