import { Guid } from '@/common/models/Guid';
import { PredictiveQuestionProperties } from '@/common/models/predictive/PredictiveQuestionProperties';
import { ScheduleTime } from '@/common/models/ScheduleTime';
import { asBoolean } from '@/common/utils/BooleanFunctions';
import { asNumber, isBetween } from '@/common/utils/NumberFunctions';
import { orderByAscending } from '@/common/utils/SortingFunctions';

import { PredictiveAnswerStyles } from './PredictiveAnswerStyles';
import { PredictiveOptionPoints } from './PredictiveOptionPoints';
import { PredictiveQuestionOption } from './PredictiveQuestionOption';
import { PredictiveQuestionStatus } from './types';

export class PredictiveQuestion {
  /**
   * The minimum points for constant-pointed questions (survey, constant-points multi choice)
   */
  private static _minConstantPoints = 0;

  id: Guid;
  segmentId: Guid;
  style: PredictiveAnswerStyles = PredictiveAnswerStyles.MultiChoice;
  order: number = 1;
  text: string = '';
  options: PredictiveQuestionOption[] = [];
  properties: PredictiveQuestionProperties;
  answerRevealed: boolean = false;
  isClosed: boolean = false;
  readonly closedAt: ScheduleTime;

  constructor(props: Partial<PredictiveQuestion> = {}) {
    Object.assign(this, props);
    this.id = props.id ? new Guid(props.id) : Guid.newGuid();
    this.answerRevealed = asBoolean(props.answerRevealed);
    this.isClosed = asBoolean(props.isClosed);
    this.closedAt = new ScheduleTime(props.closedAt);
    this.options = (props.options || [])
      .map((o) => new PredictiveQuestionOption(o))
      .sort(orderByAscending);

    this.properties = new PredictiveQuestionProperties(props.properties);
  }

  static createSliderQuestion(order: number) {
    return new PredictiveQuestion({
      style: PredictiveAnswerStyles.Slider,
      order: order,
      properties: PredictiveQuestionProperties.createSliderProperties()
    });
  }

  static createMultiChoiceQuestion(order: number) {
    return new PredictiveQuestion({
      style: PredictiveAnswerStyles.MultiChoice,
      order: order,
      options: [
        new PredictiveQuestionOption({ order: 1 }),
        new PredictiveQuestionOption({ order: 2 })
      ]
    });
  }

  static createSurveyQuestion(order: number) {
    return new PredictiveQuestion({
      style: PredictiveAnswerStyles.Survey,
      order: order,
      options: [
        new PredictiveQuestionOption({ order: 1 }),
        new PredictiveQuestionOption({ order: 2 })
      ],
      properties: new PredictiveQuestionProperties({
        ButtonText: 'Lock it in!'
      })
    });
  }

  getOption(optionId: Guid) {
    return this.options.find((x) => Guid.equals(x.id, optionId));
  }

  getAnswerText(): string {
    if (this.style === PredictiveAnswerStyles.Slider) {
      return this.properties.SliderAnswer?.toString();
    }
    return this.options
      .filter((option) => option.isCorrect)
      .map((x) => x.text)
      .join(', ');
  }

  getMinMax() {
    let max = 100;
    let min = 0;

    const maxNumber = this.properties.SliderMaxNumber;
    const minNumber = this.properties.SliderMinNumber;
    if (maxNumber !== null && maxNumber !== undefined) {
      max = +maxNumber;
    }
    if (minNumber !== null && minNumber !== undefined) {
      min = +minNumber;
    }

    return { min, max };
  }

  hasMissingOptionText() {
    return this.options.some((option) => !option.text);
  }

  hasAnswerSet(): boolean {
    return !!this.getAnswerText();
  }

  setAnswerRevealedImmutable(
    questionId: Guid,
    isRevealed: boolean
  ): PredictiveQuestion {
    if (questionId.equals(this.id)) {
      return new PredictiveQuestion({
        ...this,
        answerRevealed: isRevealed,
        isClosed: isRevealed ? true : this.isClosed
      });
    }

    return this;
  }

  getDefaultInitialResponsePercentage() {
    let totalResponsePercentage = 0;
    let totalOptionResponses = 0;
    this.options.forEach((option) => {
      if (!isNaN(option.initialResponsePercentage)) {
        totalResponsePercentage += option.initialResponsePercentage;
        totalOptionResponses += 1;
      }
    });
    return (
      (100 - totalResponsePercentage) /
      (this.options.length - totalOptionResponses)
    );
  }

  setAllInitialResponsePercentage() {
    const defaultInitialResponsePercentage =
      this.getDefaultInitialResponsePercentage();
    this.options.forEach((option) => {
      if (isNaN(option.initialResponsePercentage)) {
        option.initialResponsePercentage = defaultInitialResponsePercentage;
      }
    });
  }

  getTotalInitialResponsePercentages() {
    let totalResponsePercentage = 0;
    let totalOptionResponses = 0;
    this.options.forEach((option) => {
      if (option.initialResponsePercentage) {
        totalResponsePercentage += option.initialResponsePercentage;
        totalOptionResponses += 1;
      }
    });

    if (totalResponsePercentage > 100 || totalResponsePercentage < 0) {
      return totalResponsePercentage;
    }

    if (
      totalResponsePercentage < 100 &&
      totalOptionResponses == this.options.length
    ) {
      return totalResponsePercentage;
    }

    return 100;
  }

  areInitialResponsePercentagesValid() {
    return this.getTotalInitialResponsePercentages() === 100;
  }

  isNonSliderQuestionValid() {
    const shouldShowText = !this.properties.HideText;
    if (shouldShowText && !(this.text || '').trim()) {
      return false;
    }

    const shouldShowImage = this.properties.HasImage;
    if (shouldShowImage && !this.properties.Image?.url) {
      return false;
    }

    if (!shouldShowImage && !shouldShowText) {
      return false;
    }

    if (this.hasMissingOptionText()) {
      return false;
    }
    if (!this.areInitialResponsePercentagesValid()) {
      return false;
    }
    return true;
  }

  isSliderQuestionValid() {
    if (!this.text) {
      return false;
    }
    if (
      !this.properties.Image ||
      !this.properties.PredictiveQuestionSliderImage
    ) {
      return false;
    }
    if (!this.properties.SliderMinNumber) {
      this.properties.SliderMinNumber = 0;
    }
    if (!this.properties.SliderMaxNumber) {
      this.properties.SliderMaxNumber = 100;
    }
    if (
      this.properties.SliderMinNumber >= this.properties.SliderMaxNumber ||
      this.properties.SliderMinNumber < 0
    ) {
      return false;
    }
    if (this.properties.SliderAnswer) {
      if (!this.properties.SliderAnswerRange) {
        this.properties.SliderAnswerRange = 0;
      }
      if (
        !isBetween(
          asNumber(this.properties.SliderAnswer),
          asNumber(this.properties.SliderMinNumber),
          asNumber(this.properties.SliderMaxNumber)
        )
      ) {
        return false;
      }
    }
    if (this.properties.MaxPoints < this.getMinPoints()) {
      return false;
    }
    return true;
  }

  hasConstantPoints() {
    return (
      this.style === PredictiveAnswerStyles.Survey ||
      (this.style === PredictiveAnswerStyles.MultiChoice &&
        !this.properties.DynamicPointsEnabled) ||
      (this.style === PredictiveAnswerStyles.Slider &&
        !this.properties.SliderAnswerRange)
    );
  }

  getMinPoints() {
    if (this.hasConstantPoints()) {
      return PredictiveQuestion._minConstantPoints;
    }

    return this.properties.MinPoints;
  }

  isQuestionValid() {
    if (!this.hasInternalName()) return false;
    switch (this.style) {
      case PredictiveAnswerStyles.MultiChoice:
      case PredictiveAnswerStyles.Survey:
        return this.isNonSliderQuestionValid();

      case PredictiveAnswerStyles.Slider:
        return this.isSliderQuestionValid();
    }
  }

  hasInternalName() {
    return !!this.properties.InternalName;
  }

  getInternalName() {
    return this.properties.InternalName;
  }

  isOptionCorrect(optionId: Guid) {
    if (this.style === PredictiveAnswerStyles.Survey) {
      return true;
    }
    return (
      this.answerRevealed &&
      this.options.find((option) => option.id.equals(optionId))?.isCorrect
    );
  }

  getQuestionStatus = (
    isSegmentClosed: boolean,
    questionGuess?: Guid | number
  ) => {
    if (
      questionGuess != undefined &&
      (this.answerRevealed || this.style === PredictiveAnswerStyles.Survey)
    ) {
      let isCorrect: boolean;
      if (this.style === PredictiveAnswerStyles.Slider) {
        isCorrect = this.properties.isCorrectSliderGuess(
          questionGuess as number
        );
      } else {
        isCorrect = this.isOptionCorrect(questionGuess as Guid);
      }

      if (isCorrect) {
        return PredictiveQuestionStatus.Correct;
      }

      return PredictiveQuestionStatus.Incorrect;
    }

    if (this.properties.HasNoCorrectAnswer) {
      return PredictiveQuestionStatus.NoAnswer;
    }

    if (questionGuess != undefined && !this.answerRevealed) {
      return PredictiveQuestionStatus.Waiting;
    }

    if (
      questionGuess == undefined &&
      (isSegmentClosed || !this.isAcceptingAnswersNow())
    ) {
      return PredictiveQuestionStatus.Unanswered;
    }

    return PredictiveQuestionStatus.None;
  };

  isMultiChoiceWithDynamicPoints() {
    return (
      this.style === PredictiveAnswerStyles.MultiChoice &&
      this.properties.DynamicPointsEnabled
    );
  }

  isAcceptingAnswersNow() {
    if (
      this.isClosed ||
      this.answerRevealed ||
      this.properties.HasNoCorrectAnswer
    ) {
      return false;
    }

    const now = new Date();
    if (this.closedAt.utc && now >= this.closedAt.utc) {
      return false;
    }

    return true;
  }

  isScheduledToClose() {
    return !!this.closedAt.utc;
  }

  updateOptionPoints(optionPoints: PredictiveOptionPoints[]) {
    for (const option of this.options) {
      const points = optionPoints.find((p) => p.optionId.equals(option.id));
      if (points) {
        option.points = points;
      }
    }
  }
}
