import { Guid } from '@/common/models/Guid';
import { asNumber } from '@/common/utils/NumberFunctions';

import { SwiperOptionProperties } from '../shared/SwiperOptionProperties';

export class AdminSwiperOption {
  id: Guid;
  groupId: Guid;
  name: string;
  order: number;
  properties: SwiperOptionProperties;
  isNew?: boolean;

  constructor(props?: Partial<AdminSwiperOption>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.groupId = Guid.valueOrNew(props.groupId);
    this.order = asNumber(props.order, 1);
    this.properties = new SwiperOptionProperties(props.properties);
  }

  clone() {
    const clone = new AdminSwiperOption(this);
    clone.id = Guid.newGuid();
    clone.isNew = true;
    return clone;
  }
}
