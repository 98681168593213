import addSeconds from 'date-fns/addSeconds';
import isAfter from 'date-fns/isAfter';
import max from 'lodash/max';
import orderBy from 'lodash/orderBy';

import { Form } from '@/common/models/form/Form';
import { Guid } from '@/common/models/Guid';
import { HeightWidth } from '@/common/models/HeightWidth';
import { ImageDataModel } from '@/common/models/ImageDataModel';
import { ScheduleTime } from '@/common/models/ScheduleTime';
import { TriviaGameStatuses } from '@/common/models/trivia/Admin/TriviaGameStatuses';
import { QuestionPoolSlotTypes } from '@/common/models/trivia/shared/QuestionPoolModel';
import { utcDateOrNow, utcDateOrUndefined } from '@/common/utils/DateFunctions';
import { getSiteUrl } from '@/common/utils/SiteFunctions';

import { ITriviaGameScreen } from '../shared/GameScreens/ITriviaGameScreen';
import { TriviaGameAnswersScreen } from '../shared/GameScreens/TriviaGameAnswersScreen';
import { TriviaGameImageScreen } from '../shared/GameScreens/TriviaGameImageScreen';
import {
  TriviaGameQuestionScreen,
  TriviaQuestionAnswer
} from '../shared/GameScreens/TriviaGameQuestionScreen';
import { TriviaGameScreenTypes } from '../shared/GameScreens/TriviaGameScreenTypes';
import { TriviaActionScreenProperties } from '../shared/TriviaActionScreenProperties';
import { TriviaActionScreenTypes } from '../shared/TriviaActionScreenTypes';
import { TriviaGameProperties } from '../shared/TriviaGameProperties';
import { AdminTriviaActionScreen } from './AdminTriviaActionScreen';

interface TriviaGameStartState {
  type:
    | 'NotStarted'
    | 'NotStartedScheduled'
    | 'Started'
    | 'FinishedNotAwarded'
    | 'Finished';

  dates?: {
    start: Date;
    finish: Date;
    startsInSeconds: number;
    finishesInSeconds: number;
  };
  secondsGameplay: number;
}
export class AdminTriviaGame {
  id: Guid;
  name: string = '';
  status: TriviaGameStatuses = TriviaGameStatuses.Draft;
  isInviteOnly: boolean = false;
  secondsGameplay = 60 * 60;
  createdAt: Date;
  updatedAt: Date;

  /**
   * legacy started at, use startedAtSchedule?.utc instead
   */
  startedAt?: Date;

  startedAtSchedule?: ScheduleTime;

  prizesAwardedAt?: Date;
  prizeStatus: PrizeAwardStatuses = PrizeAwardStatuses.Pending;
  registrationFormId?: Guid;
  registrationForm?: Form;
  coverUrl = '';

  get CoverImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.coverUrl);
  }

  backgroundUrl = '';

  get BackgroundImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.backgroundUrl);
  }

  jumbotronNotStartedUrl = '';

  get JumbotronNotStartedImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.jumbotronNotStartedUrl);
  }

  jumbotronGameplayUrl = '';

  get JumbotronGameplayImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.jumbotronGameplayUrl);
  }

  jumbotronProcessingUrl = '';

  get JumbotronProcessingImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.jumbotronProcessingUrl);
  }

  jumbotronWinnerUrl = '';

  get JumbotronWinnerImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.jumbotronWinnerUrl);
  }

  screens: ITriviaGameScreen[] = [];
  properties: TriviaGameProperties;
  readonly DefaultFormHeaderText = 'Enter your details to be eligible to play';

  constructor(props?: Partial<AdminTriviaGame>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrEmpty(props.id);
    this.createdAt = utcDateOrNow(props.createdAt);
    this.updatedAt = utcDateOrNow(props.updatedAt);
    this.startedAt = utcDateOrUndefined(props.startedAt);
    this.prizesAwardedAt = utcDateOrUndefined(props.prizesAwardedAt);
    this.registrationForm = new Form(props.registrationForm);
    this.startedAtSchedule = new ScheduleTime(props.startedAtSchedule);
    this.properties = new TriviaGameProperties(props.properties);
    if (!this.registrationForm.properties.Header) {
      this.registrationForm.properties.Header = this.DefaultFormHeaderText;
    }
  }

  getNumberOfBigScreens() {
    let screenCount = 0;
    if (!!this.JumbotronGameplayImage) {
      screenCount++;
    }
    if (!!this.JumbotronProcessingImage) {
      screenCount++;
    }
    if (!!this.JumbotronWinnerImage) {
      screenCount++;
    }
    return screenCount;
  }

  get finishedAt() {
    return this.startedAt
      ? addSeconds(this.startedAt, this.secondsGameplay)
      : undefined;
  }

  get finishedAtSchedule() {
    return this.startedAt
      ? new ScheduleTime({
          utc: addSeconds(this.startedAt, this.secondsGameplay),
          timeZoneId: this.startedAtSchedule?.timeZoneId
        })
      : undefined;
  }

  getZonedFinishedAtOrUndefined(): Date | undefined {
    const zonedStart = this.startedAtSchedule?.tryGetZonedTime();
    return zonedStart
      ? addSeconds(zonedStart, this.secondsGameplay)
      : undefined;
  }

  getFinishedAtUtcOrUndefined(): Date | undefined {
    const utcStart = this.startedAtSchedule?.utc;
    return utcStart ? addSeconds(utcStart, this.secondsGameplay) : undefined;
  }

  getRulesScreen() {
    return this.getImageScreen(TriviaGameScreenTypes.Rules);
  }

  getProcessingScreen() {
    return this.getImageScreen(TriviaGameScreenTypes.Processing);
  }

  getLobbyScreen() {
    return this.getImageScreen(TriviaGameScreenTypes.Lobby);
  }

  getFinishedScreen() {
    return this.getImageScreen(TriviaGameScreenTypes.Finished);
  }

  getQuestionScreen(id: Guid) {
    const index = this.screens.findIndex(
      (x) =>
        x.type === TriviaGameScreenTypes.QuizQuestion && Guid.equals(x.id, id)
    );
    if (index < 0)
      return [undefined, index] as [TriviaGameQuestionScreen, number];
    const screen = new TriviaGameQuestionScreen(this.screens[index]);
    return [screen, index] as [TriviaGameQuestionScreen, number];
  }

  getQuestionScreens() {
    let screens: TriviaGameQuestionScreen[] = [];
    if (!this.screens?.length) return screens;
    screens = this.screens
      .filter((x) => x.type === TriviaGameScreenTypes.QuizQuestion)
      .map((x) => new TriviaGameQuestionScreen(x));

    return orderBy(screens, (x) => x.order, 'asc');
  }

  getNextAndPreviousQuestionScreens(currentScreenId: Guid): {
    nextScreen?: TriviaGameQuestionScreen;
    previousScreen?: TriviaGameQuestionScreen;
  } {
    let screens: TriviaGameQuestionScreen[] = [];

    if (this.properties.QuestionPoolEnabled) {
      screens = this.getSpecificAndPoolQuestionsOrderedForPortalNavigation();
    } else {
      screens = this.getQuestionScreens();
    }

    const currentQuestionIndex = screens.findIndex((x) =>
      x.id.equals(currentScreenId)
    );
    let nextScreen = null;
    let previousScreen = null;
    if (
      currentQuestionIndex !== -1 &&
      currentQuestionIndex + 1 < screens.length
    ) {
      nextScreen = screens[currentQuestionIndex + 1];
    }
    if (currentQuestionIndex !== -1 && currentQuestionIndex - 1 >= 0) {
      previousScreen = screens[currentQuestionIndex - 1];
    }
    return {
      nextScreen,
      previousScreen
    };
  }

  /**
   * Pool questions are questions that are not assigned to a specific slot
   */
  getPoolQuestions() {
    const specificQuestionSlots =
      this.properties.questionPoolModel.slots.filter(
        (x) =>
          x.type === QuestionPoolSlotTypes.SpecificQuestion && !!x.questionId
      );

    return this.getQuestionScreens().filter((x) =>
      specificQuestionSlots.every((y) => !y.questionId.equals(x.id))
    );
  }

  getSpecificAndPoolQuestionsOrderedForPortalNavigation() {
    const specificQuestionSlots = orderBy(
      this.properties.questionPoolModel.slots.filter(
        (x) =>
          x.type === QuestionPoolSlotTypes.SpecificQuestion && !!x.questionId
      ),
      (x) => x.order,
      'asc'
    );

    const questionScreens = this.getQuestionScreens();
    const specificQuestions = specificQuestionSlots
      .map((x) => questionScreens.find((y) => y.id.equals(x.questionId)))
      .filter((x) => !!x);
    const poolQuestions = questionScreens.filter((x) =>
      specificQuestionSlots.every((y) => !y.questionId.equals(x.id))
    );

    return [...specificQuestions, ...poolQuestions];
  }

  isPoolQuestion(questionId: Guid) {
    return this.getPoolQuestions().some((x) => Guid.equals(x.id, questionId));
  }

  getPoolQuestionIndex(questionId: Guid) {
    if (!this.isPoolQuestion(questionId)) return undefined;
    return this.getPoolQuestions().findIndex((x) =>
      Guid.equals(x.id, questionId)
    );
  }

  tryGetSpecificQuestionSlot(questionId: Guid) {
    return this.properties.questionPoolModel.slots.find(
      (x) =>
        x.type === QuestionPoolSlotTypes.SpecificQuestion &&
        Guid.equals(x.questionId, questionId)
    );
  }

  getAnswersScreen() {
    const index = this.screens.findIndex(
      (x) => x.type === TriviaGameScreenTypes.Answers
    );
    if (index < 0)
      return [undefined, index] as [TriviaGameAnswersScreen, number];
    const screen = new TriviaGameAnswersScreen(this.screens[index]);
    return [screen, index] as [TriviaGameAnswersScreen, number];
  }

  getStartState() {
    const now = new Date();
    const nowTime = now.getTime();

    const start = this.startedAtSchedule?.utc;
    const state: TriviaGameStartState = {
      type: 'NotStarted',
      secondsGameplay: this.secondsGameplay
    };

    if (start) {
      state.dates = {
        start,
        finish: this.getFinishedAtUtcOrUndefined(),
        startsInSeconds: 0,
        finishesInSeconds: 0
      };
    }

    if (!state.dates) {
      return state;
    }

    if (isAfter(state.dates.start, now)) {
      state.type = 'NotStartedScheduled';
      const startTime = state.dates.start.getTime();
      const finishTime = state.dates.finish.getTime();
      state.dates.startsInSeconds = max([
        Math.ceil((startTime - nowTime) / 1000),
        0
      ]);
      state.dates.finishesInSeconds = max([
        Math.ceil((finishTime - nowTime) / 1000),
        0
      ]);
      return state;
    }

    if (!isAfter(now, state.dates.finish)) {
      state.type = 'Started';
      const finishTime = state.dates.finish.getTime();
      state.dates.finishesInSeconds = max([
        Math.ceil((finishTime - nowTime) / 1000),
        0
      ]);
    } else {
      state.type = !!this.properties.AnswersRevealedAt
        ? 'Finished'
        : 'FinishedNotAwarded';
    }
    return state;
  }

  isSharingEnabled() {
    const facebook = this.properties.FacebookSharingDisabled;
    const messenger = this.properties.MessengerSharingDisabled;
    const whatsApp = this.properties.WhatsappSharingDisabled;
    const twitter = this.properties.TwitterSharingDisabled;
    const web = this.properties.WebsiteSharingDisabled;

    return !facebook || !messenger || !whatsApp || !twitter || !web;
  }

  setSharingEnabled(enabled: boolean) {
    this.properties.FacebookSharingDisabled = !enabled;
    this.properties.MessengerSharingDisabled = !enabled;
    this.properties.WhatsappSharingDisabled = !enabled;
    this.properties.TwitterSharingDisabled = !enabled;
    this.properties.WebsiteSharingDisabled = !enabled;
  }

  getBackgroundUploadProps() {
    const size: HeightWidth = { height: 1920, width: 1080 };
    return {
      path: `live/${this.id}/background`,
      size
    };
  }

  generateNewQuestionScreen() {
    return new TriviaGameQuestionScreen({
      questionText: 'Question',
      shuffleAnswers: true,
      answers: [
        new TriviaQuestionAnswer({
          answerText: 'Correct',
          isCorrect: true
        }),
        new TriviaQuestionAnswer({
          answerText: 'Incorrect',
          isCorrect: false
        })
      ]
    });
  }

  generateNewSurveyQuestionScreen() {
    return new TriviaGameQuestionScreen({
      questionText: 'Survey',
      isSurvey: true,
      shuffleAnswers: true,
      answers: [
        new TriviaQuestionAnswer({
          answerText: 'Opinion 1'
        }),
        new TriviaQuestionAnswer({
          answerText: 'Opinion 2'
        })
      ]
    });
  }

  newLeaderboardActionScreen(siteDomain: string, order?: number) {
    return new AdminTriviaActionScreen({
      order,
      name: 'Leaderboard',
      type: TriviaActionScreenTypes.Leaderboard,
      properties: new TriviaActionScreenProperties({
        header: 'Can you beat them?',
        callToAction: 'Play now at',
        url: getSiteUrl(siteDomain, true)
      })
    });
  }

  newBeatTheScoreActionScreen(siteDomain: string, order?: number) {
    return new AdminTriviaActionScreen({
      order,
      name: 'Beat the Score',
      type: TriviaActionScreenTypes.BeatScore,
      properties: new TriviaActionScreenProperties({
        header: 'Can you beat them?',
        subHeader: 'Can you beat {expert name}?',
        expertScore: 0,
        callToAction: 'Play now at',
        url: getSiteUrl(siteDomain, true)
      })
    });
  }

  newHardestQuestionActionScreen(siteDomain: string, order?: number) {
    return new AdminTriviaActionScreen({
      order,
      name: 'Hardest Question',
      type: TriviaActionScreenTypes.HardestQuestion,
      properties: new TriviaActionScreenProperties({
        subHeader: 'Can you answer the hardest question?',
        callToAction: 'Play now at',
        url: getSiteUrl(siteDomain, true)
      })
    });
  }

  newQuestionResultsActionScreen(siteDomain: string, order?: number) {
    return new AdminTriviaActionScreen({
      order,
      name: 'Question Results',
      type: TriviaActionScreenTypes.Question,
      properties: new TriviaActionScreenProperties({
        subHeader: 'Have your say!',
        callToAction: 'Play now at',
        url: getSiteUrl(siteDomain, true)
      })
    });
  }

  newSmartestTeamActionScreen(siteDomain: string, order?: number) {
    return new AdminTriviaActionScreen({
      order,
      name: 'Smartest Team',
      type: TriviaActionScreenTypes.SmartestTeam,
      properties: new TriviaActionScreenProperties({
        subHeader: 'Who has the smartest fans?',
        callToAction: 'Play now at',
        url: getSiteUrl(siteDomain, true)
      })
    });
  }

  newActionScreen(
    siteDomain: string,
    screenCount: number,
    type: TriviaActionScreenTypes
  ) {
    switch (type) {
      case TriviaActionScreenTypes.BeatScore:
        return this.newBeatTheScoreActionScreen(siteDomain, screenCount);
      case TriviaActionScreenTypes.Leaderboard:
        return this.newLeaderboardActionScreen(siteDomain, screenCount);
      case TriviaActionScreenTypes.HardestQuestion:
        return this.newHardestQuestionActionScreen(siteDomain, screenCount);
      case TriviaActionScreenTypes.Question:
        return this.newQuestionResultsActionScreen(siteDomain, screenCount);
      case TriviaActionScreenTypes.SmartestTeam:
        return this.newSmartestTeamActionScreen(siteDomain, screenCount);
      default:
        throw `Missing add feed for ${type}`;
    }
  }

  private getImageScreen(type: TriviaGameScreenTypes) {
    const index = this.screens.findIndex((x) => x.type === type);
    if (index < 0) return [undefined, index] as [TriviaGameImageScreen, number];
    const screen = new TriviaGameImageScreen(this.screens[index]);
    return [screen, index] as [TriviaGameImageScreen, number];
  }
}

enum PrizeAwardStatuses {
  Pending = 'Pending',
  Awarding = 'Awarding',
  Awarded = 'Awarded'
}
