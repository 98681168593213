import { nanoid } from 'nanoid';

import { HeightWidth } from '@/common/models/HeightWidth';
import { ImageDataModel } from '@/common/models/ImageDataModel';
import { XY } from '@/common/models/Xy';
import { asBoolean } from '@/common/utils/BooleanFunctions';

export class CanvasBackground {
  id: string;
  imageUrl?: string;

  get Image() {
    return ImageDataModel.fromJsonOrUrl(this.imageUrl);
  }

  base64Image?: string;
  color?: string;
  transform?: CanvasBackgroundTransform;
  isUserUpload: boolean;

  isDefault(): boolean {
    return (
      !this.Image &&
      !this.base64Image &&
      (!this.color || this.color === CanvasBackground.DefaultColor)
    );
  }
  static DefaultColor = '#FFFFFF';
  constructor(props?: Partial<CanvasBackground>) {
    props = props || {};
    Object.assign(this, props);
    this.isUserUpload = asBoolean(props.isUserUpload, false);
    if (!this.id) this.id = nanoid();
    if (props.transform) {
      this.transform = new CanvasBackgroundTransform(props.transform);
    }
    if (!this.Image && !this.base64Image && !this.color) {
      this.color = CanvasBackground.DefaultColor;
    }
  }
}

/**
 * Transform - dimension and position. IMPORTANT: numbers are stored as a fraction of the
 * stage size they were defined on, to account for screen resizing.
 */
export class CanvasBackgroundTransform {
  dimensions: HeightWidth;
  position: XY;

  constructor(props?: Partial<CanvasBackgroundTransform>) {
    props = props || {};
    Object.assign(this, props);
  }

  public xOnStage(stageSize: HeightWidth) {
    return this.position.x * stageSize.width;
  }

  public yOnStage(stageSize: HeightWidth) {
    return this.position.y * stageSize.height;
  }

  public widthOnStage(stageSize: HeightWidth) {
    return this.dimensions.width * stageSize.width;
  }

  public heightOnStage(stageSize: HeightWidth) {
    return this.dimensions.height * stageSize.height;
  }

  public static createRelativeToStage = (
    dimensions: HeightWidth,
    position: XY,
    stageSize: HeightWidth
  ) => {
    return new CanvasBackgroundTransform({
      dimensions: {
        width: dimensions.width / stageSize.width,
        height: dimensions.height / stageSize.height
      },
      position: {
        x: position.x / stageSize.width,
        y: position.y / stageSize.height
      }
    });
  };
}
