import { Dispatch, SetStateAction } from 'react';

import { ToasterContextState } from '@/common/components/Toast/ToasterContextState';
import { useToaster } from '@/common/components/Toast/useToaster';
import { ApiError } from '@/common/data/ApiError';
import { useConstantCallback } from '@/common/hooks/useConstant';
import { useLatest } from '@/common/hooks/useLatest';
import { useStateIfMounted } from '@/common/hooks/useStateIfMounted';

export type ActionHandlerDecorator<T = any> = (
  handleAction: Promise<T>
) => Promise<T>;
export type HandleAsync = <T = any, TSource = string>(
  action: () => Promise<T>,
  options?: UseHandleActionOptions<T, TSource>
) => Promise<T>;

interface InitOptions {
  isHandlingDefault?: boolean;
  isHandlingDefaultSource?: string;
  decorator?: ActionHandlerDecorator<any>;
}

export interface UseHandleActionOptions<TResponse = any, TSource = string>
  extends HandleActionOptions<TResponse> {
  noToastOnError?: boolean;
  noLogOnError?: boolean;
  throwOnError?: boolean;
  source?: TSource;
}

export interface AsyncHandlingState<TSource = string> {
  isHandling: boolean;
  handlingSource?: TSource;
}

export type AsyncActionHandler<TSource = string> = <TResponse = any>(
  action: () => Promise<TResponse>,
  options?: UseHandleActionOptions<TResponse, TSource>
) => Promise<TResponse>;

export type UseActionHandlerResponse<TSource = string> = [
  AsyncActionHandler<TSource>,
  AsyncHandlingState<TSource>,
  Dispatch<SetStateAction<AsyncHandlingState<TSource>>>
];

const unknownSource = 'Unknown';
export function useActionHandler<TSource = string>(options?: InitOptions) {
  const toaster = useToaster();
  const decoratorRef = useLatest(options?.decorator);
  const [handling, setHandling] = useStateIfMounted<
    AsyncHandlingState<TSource>
  >({
    isHandling: options?.isHandlingDefault || false,
    handlingSource: options?.isHandlingDefaultSource || (unknownSource as any)
  });

  const handleAsync = useConstantCallback<AsyncActionHandler<TSource>>(
    <TResponse>(action, options) => {
      return handleHookActionAsync<TSource, TResponse>(
        action,
        toaster,
        setHandling,
        options,
        decoratorRef.current
      );
    }
  );

  return [
    handleAsync,
    handling,
    setHandling
  ] as UseActionHandlerResponse<TSource>;
}

function handleHookActionAsync<TSource = string, TResponse = any>(
  action: () => Promise<TResponse>,
  toaster: ToasterContextState,
  setHandling: Dispatch<SetStateAction<AsyncHandlingState<TSource>>>,
  options?: UseHandleActionOptions<TResponse, TSource>,
  decorator?: ActionHandlerDecorator<TResponse>
) {
  return handleActionAsync(
    action,
    {
      onStart: () => {
        setHandling({
          isHandling: true,
          handlingSource: options?.source || (unknownSource as any)
        });
        options?.onStart?.();
      },
      onSettled: options?.onSettled,
      onSuccess: (r) => {
        options?.onSuccess?.(r);
        setHandling({
          isHandling: false,
          handlingSource: unknownSource as any
        });
      },
      noLogOnError: options?.noLogOnError,
      onError: (e) => {
        if (!options?.noToastOnError) {
          toaster.error(ApiError.tryGetMessage(e));
        }
        setHandling({
          isHandling: false,
          handlingSource: unknownSource as any
        });
        options?.onError?.(e);

        if (options?.throwOnError) {
          throw e;
        }
      }
    },
    decorator
  );
}

export interface HandleActionOptions<TResponse = any> {
  onStart?: () => void;
  onSuccess?: (response?: TResponse) => void;
  onError?: (response?: any) => void;
  noLogOnError?: boolean;
  onSettled?: () => void;
}

export async function handleActionAsync<TResponse = any>(
  action: () => Promise<TResponse>,
  options?: HandleActionOptions<TResponse>,
  decorator?: ActionHandlerDecorator<TResponse>
) {
  return !!decorator
    ? decorator(baseHandleActionAsync(action, options))
    : baseHandleActionAsync(action, options);
}

async function baseHandleActionAsync<TResponse = any>(
  action: () => Promise<TResponse>,
  options?: HandleActionOptions<TResponse>
) {
  options?.onStart?.();
  try {
    const response = await action();
    options?.onSettled?.();
    if (options?.onSuccess) options.onSuccess(response);
    return response;
  } catch (e) {
    if (!options?.noLogOnError) {
      console.error(e);
    }
    options?.onSettled?.();
    options?.onError?.(e);
  }
}
