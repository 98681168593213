import { Guid } from '@/common/models/Guid';
import { ImageDataModel } from '@/common/models/ImageDataModel';
import { mapArray } from '@/common/utils/ArrayFunctions';
import { asNumber } from '@/common/utils/NumberFunctions';

import { LogicRules } from './LogicRules';
import { QuizQuestionOptionWeighting } from './QuizQuestionOptionWeighting';

export class QuizQuestionOption {
  id: Guid;
  questionId: Guid;
  text: string = '';
  imageUrl: string;

  get image(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.imageUrl);
  }

  order: number;
  isCorrect: boolean = false;
  logicRule: LogicRules = LogicRules.NextQuestion;
  jumpToQuestionId?: Guid;
  resultWeightings: QuizQuestionOptionWeighting[];

  constructor(partial: Partial<QuizQuestionOption> = {}) {
    this.applyDefaults(partial);
  }

  update(partial: Partial<QuizQuestionOption>) {
    if (!partial) {
      return;
    }

    this.applyDefaults({ ...this, ...partial });
  }

  private applyDefaults(partial: Partial<QuizQuestionOption>) {
    partial = partial || {};
    Object.assign(this, partial);
    this.id = Guid.valueOrNew(partial.id);
    this.order = asNumber(partial.order, 1);
    this.questionId = Guid.valueOrEmpty(partial.questionId);
    this.jumpToQuestionId = Guid.valueOrUndefined(partial.jumpToQuestionId);
    this.resultWeightings = mapArray(
      partial.resultWeightings,
      (x) => new QuizQuestionOptionWeighting(x)
    );
  }
}
