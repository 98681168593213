import { darken, isLightColor, lighten } from '@mantine/core';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';

import { Guid } from '@/common/models/Guid';
import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { hasLogicConfigured } from '@/common/models/quiz/QuizLogicFunctions';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';
import { mapArray, tryUpdateItemInArray } from '@/common/utils/ArrayFunctions';
import {
  getPrimaryBackgroundColor,
  getPrimaryTextColor
} from '@/common/utils/ButtonFunctions';
import { orderByAscending } from '@/common/utils/SortingFunctions';

import { CardDescriptor } from '../CardDescriptor';
import { CardTypes } from '../CardTypes';
import { Competition } from '../competitions/Competition';
import { ContentLibraryQuestion } from '../content-library/ContentLibraryQuestion';
import { ExternalLinkButtonModel } from '../ExternalLinkButtonModel';
import { SiteCard } from '../SiteCard';
import { QuizAnswerStyles } from './QuizAnswerStyles';
import { QuizBridge } from './QuizBridge';
import { createQuizQuestion, createQuizQuestionOption } from './QuizFactory';
import { QuizProperties } from './QuizProperties';
import { QuizQuestion } from './QuizQuestion';
import { QuizQuestionOption } from './QuizQuestionOption';
import { QuizResult } from './QuizResult';
import { QuizResultProperties } from './QuizResultProperties';
import { QuizScreen } from './QuizScreen';
import { QuizStyles } from './QuizStyles';
import { QuizStylesFront } from './QuizStylesFront';

export class Quiz implements SiteCard {
  static DefaultReplayText = 'Play again';
  static DefaultViewAnswersText = 'View answers';

  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.Quiz;
  name: string;
  style: QuizStyles = QuizStyles.Assessment;
  properties: QuizProperties;
  questions: QuizQuestion[];
  screens?: QuizScreen[];
  results: QuizResult[];

  get showViewAnswers() {
    return this.properties.ViewAnswersEnabled;
  }

  constructor(props: Partial<Quiz> = {}) {
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
    this.questions = mapArray(
      props.questions,
      (x: any) => new QuizQuestion(x)
    ).sort(orderByAscending);
    this.properties = new QuizProperties(props.properties);
    this.results = mapArray(props.results, (x) => new QuizResult(x));

    if (props.screens) {
      this.screens = mapArray(props.screens, (x) => new QuizScreen(x));
    }
  }

  getQuizStyleFront(): QuizStylesFront {
    if (this.isMultiPoll()) return 'Multi-poll';
    if (this.isPoll()) return 'Poll';
    if (this.isPersonality()) return 'Personality quiz';

    return 'Quiz';
  }

  getQuizStyleVars() {
    const textC = this.properties.QuestionBodyTextColor;

    const optionTextC = this.properties.QuestionOptionTextColor;

    return {
      '--komo-card-shell-bg': this.properties.QuestionBodyBackgroundColor,
      '--komo-card-shell-c': textC,
      '--komo-card-shell-dimmed-c': !textC
        ? undefined
        : isLightColor(textC, 0.4)
          ? darken(textC, 0.3)
          : lighten(textC, 0.3),
      '--question-header-bg-color':
        this.properties.QuestionHeaderBackgroundColor,
      '--question-header-color': this.properties.QuestionHeaderTextColor,
      '--question-navigation-active-color':
        this.properties.QuestionHeaderNavigationActiveColor,
      '--question-navigation-inactive-color':
        this.properties.QuestionHeaderNavigationInactiveColor,
      '--question-body-background': this.properties.QuestionBodyBackgroundColor,
      '--question-body-color': this.properties.QuestionBodyTextColor,
      '--question-option-background':
        this.properties.QuestionOptionBackgroundColor,
      '--question-option-color': optionTextC,
      '--question-option-dimmed-color': !optionTextC
        ? undefined
        : isLightColor(optionTextC, 0.4)
          ? darken(optionTextC, 0.15)
          : lighten(optionTextC, 0.15),
      '--question-option-fill-color': this.properties.QuestionOptionFillColor
    };
  }

  isPoll() {
    return this.style === QuizStyles.Poll && this.questions.length <= 1;
  }

  isMultiPoll() {
    return this.style === QuizStyles.Poll && this.questions.length > 1;
  }

  isPollOrMultiPoll() {
    return this.style === QuizStyles.Poll;
  }

  isPersonality() {
    return this.style === QuizStyles.Personality;
  }

  isScoredQuiz() {
    return this.style === QuizStyles.Scored;
  }

  isAssessmentQuiz() {
    return this.style === QuizStyles.Assessment;
  }

  isAnyQuiz() {
    return (
      this.style === QuizStyles.Scored || this.style === QuizStyles.Assessment
    );
  }

  getQuizEndingType() {
    return this.isPollOrMultiPoll() ? 'Ending' : 'Results';
  }

  getQuestion(id: Guid): QuizQuestion | undefined {
    if (!id) return undefined;
    const match = this.questions.findIndex((q) => q.id.equals(id));
    return match === -1 ? undefined : this.questions[match];
  }

  updateQuizQuestion(questionId: Guid, updatedQuestion: Partial<QuizQuestion>) {
    const question = this.getQuestion(questionId);
    if (question) {
      question.update({
        ...question,
        ...updatedQuestion,
        properties: { ...question.properties, ...updatedQuestion.properties }
      });
    }
  }

  addQuizQuestionOption(questionId: Guid) {
    const question = this.getQuestion(questionId);

    if (question) {
      question.addOption();
    }
  }

  updateQuizQuestionOption(
    questionId: Guid,
    optionId: Guid,
    option: Partial<QuizQuestionOption>
  ) {
    const question = this.getQuestion(questionId);
    if (question) {
      question.updateOption(optionId, option);
    }
  }

  deleteQuizQuestionOption(questionId: Guid, optionId: Guid) {
    const question = this.getQuestion(questionId);
    if (question) {
      question.deleteOption(optionId);
    }
  }

  addQuestion(style?: QuizAnswerStyles) {
    const defaultStyle =
      this.style === QuizStyles.Poll
        ? QuizAnswerStyles.Poll
        : QuizAnswerStyles.Single;

    const newQuestion = createQuizQuestion(
      this.id,
      style || defaultStyle,
      this.nextQuestionOrBridgeOrder()
    );

    this.questions = [...this.questions, newQuestion];
    return newQuestion;
  }

  importFromContentLibrary(contentQuestions: ContentLibraryQuestion[]) {
    const nextOrder = this.nextQuestionOrBridgeOrder();
    const newQuestions = contentQuestions.map((q, idx) => {
      const quizQuestion = createQuizQuestion(
        this.id,
        QuizAnswerStyles.Single,
        nextOrder + idx
      );

      quizQuestion.text = q.text;
      quizQuestion.options = q.options.map((o, i) => {
        const option = createQuizQuestionOption(i, o.isCorrect);
        return new QuizQuestionOption({ ...option, text: o.text });
      });

      return quizQuestion;
    });

    this.questions = [...this.questions, ...newQuestions];
  }

  deleteQuestion(id: Guid) {
    if (this.questions.length === 1) {
      return;
    }

    const newQuestions = this.questions
      .filter((q) => !q.id.equals(id))
      .sort(orderByAscending);

    this.setQuestionsAndBridges([
      ...newQuestions,
      ...this.properties.getBridgeScreens()
    ]);

    this.invalidateResults(this.questions.length);
  }

  updateQuestionAnswerStyle(questionId: Guid, newStyle: QuizAnswerStyles) {
    if (this.style === QuizStyles.Poll) {
      // We cannot change the question style, when
      // its a poll - all questions are polls
      return;
    }

    const question = this.getQuestion(questionId);
    if (question) {
      question.style = newStyle;
    }
  }

  findQuestionOrBridge(id: Guid) {
    return this.getQuestionsAndBridges().find((x) => x.id.equals(id));
  }

  reorderQuestionsAndBridges(sourceIdx: number, destinationIdx: number) {
    const orderedItems = this.getQuestionsAndBridges().map((x, idx) => {
      x.order = idx;
      return x;
    });

    const newQuestionsAndBridges = orderedItems.map((q) => {
      if (q.order === sourceIdx) {
        q.order = destinationIdx;
        return q;
      }

      // reordering item backward bumps up intermediate items
      if (q.order <= sourceIdx && q.order >= destinationIdx) {
        ++q.order;
        return q;
      }

      // reordering item forward bumps down intermediate items
      if (q.order >= sourceIdx && q.order <= destinationIdx) {
        --q.order;
        return q;
      }

      return q;
    });

    this.setQuestionsAndBridges(newQuestionsAndBridges);
  }

  getGameplayModel(
    id: Guid
  ): QuizQuestion | QuizBridge | QuizResult | undefined {
    const result = this.getResult(id);
    if (result) return result;

    const items = this.getQuestionsAndBridges();
    return items.find((x) => x.id.equals(id));
  }

  getQuestionsAndBridges(): (QuizQuestion | QuizBridge)[] {
    return [...this.questions, ...this.properties.getBridgeScreens()].sort(
      orderByAscending
    );
  }

  getLastQuestionOrBridge(): QuizQuestion | QuizBridge {
    return this.getQuestionsAndBridges().at(-1);
  }

  setQuestionsAndBridges(questionsAndBridges: (QuizQuestion | QuizBridge)[]) {
    const reorderedQuestionsAndBridges = questionsAndBridges
      .sort(orderByAscending)
      .map((q, idx) => {
        q.order = idx;
        return q;
      });

    const newQuestions: QuizQuestion[] = reorderedQuestionsAndBridges.filter(
      (x) => x instanceof QuizQuestion
    ) as QuizQuestion[];
    const newBridges = reorderedQuestionsAndBridges.filter(
      (x) => x instanceof QuizBridge
    ) as QuizBridge[];

    this.questions = newQuestions;
    this.properties.setBridgeScreens(newBridges);
  }

  nextQuestionOrBridgeOrder(): number {
    const lastItemOrder = [...this.getQuestionsAndBridges()].pop()?.order || 0;
    return lastItemOrder + 1;
  }

  getResultByIdx(idx: number): QuizResult | undefined {
    if (idx < 0 || idx >= this.results.length) return undefined;
    return this.results[idx];
  }

  getResult(resultId: Guid): QuizResult | undefined {
    const match = this.results.findIndex((s) => s.id.equals(resultId));
    return match === -1 ? undefined : this.results[match];
  }

  setResults(results: QuizResult[]) {
    this.results = results;
  }

  updateResult(
    resultId: Guid,
    partial: Partial<QuizResult>,
    partialProperties?: Partial<QuizResultProperties>
  ) {
    const resultsCopy = [...this.results];
    tryUpdateItemInArray<QuizResult>(
      resultsCopy,
      (r) => Guid.equals(r.id, resultId),
      (result) =>
        new QuizResult({
          ...result,
          ...partial,
          properties: { ...result.properties, ...partialProperties }
        })
    );
    this.setResults(resultsCopy);
  }

  updateResultProperties(
    resultId: Guid,
    partial: Partial<QuizResultProperties>
  ) {
    this.updateResult(resultId, {}, partial);
  }

  addResult(): QuizResult {
    const result = new QuizResult();
    this.setResults([...this.results, result]);
    return result;
  }

  deleteResult(resultId: Guid) {
    this.setResults(
      this.results
        .filter((s) => !s.id.equals(resultId))
        .map((s) => new QuizResult({ ...s }))
    );
  }

  addResultButton(resultId: Guid) {
    const result = this.getResult(resultId);
    if (result) {
      const newButtons = [
        ...result.buttons.map((q) => new ExternalLinkButtonModel(q)),
        new ExternalLinkButtonModel({
          borderColor: '#000000',
          textColor: '#000000'
        })
      ];
      this.updateResult(result.id, {
        ...result,
        buttons: newButtons
      });
    }
  }

  deleteResultButton(resultId: Guid, buttonId: Guid) {
    const screen = this.getResult(resultId);
    if (screen) {
      const buttons = screen.buttons.filter((b) => !b.id.equals(buttonId));
      this.updateResult(screen.id, { ...screen, buttons: buttons });
    }
  }

  updateResultButton(
    resultId: Guid,
    buttonId: Guid,
    partial: Partial<ExternalLinkButtonModel>
  ) {
    const screen = this.getResult(resultId);

    if (screen) {
      const buttons = screen.buttons.map((b) => {
        if (b.id.equals(buttonId)) {
          return new ExternalLinkButtonModel({ ...b, ...partial });
        }
        return b;
      });
      this.updateResult(screen.id, { ...screen, buttons });
    }
  }

  invalidateResults(quizQuestionLength: number) {
    const updatedResults = this.results.map((r) => {
      if (
        isNumber(r.maxCorrectAnswers) &&
        quizQuestionLength < r.maxCorrectAnswers
      ) {
        return new QuizResult({
          ...r,
          minCorrectAnswers: undefined,
          maxCorrectAnswers: undefined
        });
      }

      return r;
    });

    this.setResults(updatedResults);
  }

  isValidResult(result: QuizResult): boolean {
    if (isNil(result)) {
      return true;
    }
    return this.isAnyQuiz()
      ? result.hasResultRange() && result.hasContent()
      : result.hasContent();
  }

  getCoverButtonTextColorAsString(properties: SitePageProperties) {
    return this.properties.getCoverButtonTextColorAsString(properties);
  }

  getCoverButtonBackgroundColorAsString(properties: SitePageProperties) {
    return this.properties.getCoverButtonBackgroundColorAsString(properties);
  }

  getNextScreenButtonProps(properties: SitePageProperties) {
    return this.properties.getNextScreenButtonProps(properties);
  }

  addBridgeScreen() {
    const order = this.nextQuestionOrBridgeOrder();
    return this.properties.addBridgeScreen(order);
  }

  getSubheader(correctAnswers: number | string) {
    return !hasLogicConfigured(this.questions) && this.isAssessmentQuiz()
      ? `YOU GOT ${correctAnswers} OUT OF ${this.questions.length} CORRECT`
      : undefined;
  }

  getCompetitionStylingVariables(
    competition: Competition,
    properties: SitePageProperties,
    cardDescriptor: CardDescriptor,
    ctaTextOverride?: string
  ) {
    const variables = competition.getStylingVariables(
      properties,
      this,
      ctaTextOverride
    );

    if (cardDescriptor?.properties.CoverEnabled) {
      const overrideBackgroundColor =
        this.getCoverButtonBackgroundColorAsString(properties);
      if (overrideBackgroundColor) {
        variables.successBackgroundColor = overrideBackgroundColor;
      }

      const overrideTextColor =
        this.getCoverButtonTextColorAsString(properties);
      if (overrideTextColor) {
        variables.successTextColor = overrideTextColor;
      }
    }

    return variables;
  }

  getFallbackButtonColors(properties: SitePageProperties) {
    return {
      backgroundColor: getPrimaryBackgroundColor({
        card: this,
        properties
      }),
      color: getPrimaryTextColor({
        card: this,
        properties
      })
    };
  }

  shouldHideAnswers() {
    return this.style === QuizStyles.Poll || this.properties.HideAnswers;
  }

  shouldHideAnswersForQuestion(question: QuizQuestion) {
    return (
      !this.isAnyQuiz() ||
      this.properties.HideAnswers ||
      question.style === QuizAnswerStyles.Poll
    );
  }
}
