import { immerable } from 'immer';

import { ButtonDataModel } from '@/common/models/ButtonDataModel';
import { Guid } from '@/common/models/Guid';
import { ImageDataModel } from '@/common/models/ImageDataModel';
import { SiteCardProperties } from '@/common/models/SiteCardProperties';
import { asBoolean } from '@/common/utils/BooleanFunctions';
import { asFloat, asNumber } from '@/common/utils/NumberFunctions';

import { TreasureHuntDrawerProperties } from './TreasureHuntDrawerProperties';

const colors = {
  black: '#000000',
  white: '#FFFFFF',
  lightBg: '#e9ecef'
};
export class TreasureHuntProperties implements SiteCardProperties {
  [immerable] = true;

  public static readonly SubmitButtonTextPlaceholder = 'Submit';

  CheckpointOrder: string;
  ScoreEnabled: boolean;
  DefaultPoints: number;

  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;

  CheckpointsLogoImageDataModelJson?: string;
  CheckpointsLogoEnabled: boolean;
  CheckpointsCoverImageDataModelJson?: string;
  CheckpointsCoverEnabled: boolean;
  CheckpointsTitle?: string;
  CheckpointsTitleEnabled: boolean;
  CheckpointsDescription?: string;
  CheckpointsDescriptionEnabled: boolean;

  GameClosedTitle: string = 'Game closed';
  GameClosedTitleEnabled: boolean;
  GameClosedDescription?: string;
  GameClosedDescriptionEnabled: boolean;

  CheckpointsHeaderTextColor: string = colors.black;
  CheckpointsHeaderBackgroundColor: string = colors.white;

  CheckpointsListBackgroundColor: string = colors.lightBg;
  CheckpointsListItemBackgroundColor: string = colors.white;
  CheckpointsListItemTextColor: string = colors.black;
  CheckpointsListItemScoreBackgroundColor: string = colors.lightBg;
  CheckpointsListItemScoreTextColor: string = colors.black;

  CheckpointsListFilterInactiveBackgroundColor: string = colors.lightBg;
  CheckpointsListFilterInactiveTextColor: string = colors.black;
  CheckpointsListFilterActiveBackgroundColor: string = colors.white;
  CheckpointsListFilterActiveTextColor: string = colors.black;

  CheckpointsListItemImageTextCodeBackgroundColor: string = '#d3f9d8';
  CheckpointsListItemImageQrBackgroundColor: string = '	#99e9f2';
  CheckpointsListItemImageTextColor: string = colors.black;

  CheckpointDrawerPropertiesJson: string;
  MapDrawerPropertiesJson: string;
  RulesDrawerPropertiesJson: string;

  CheckpointSubmitButtonDataModelJson: string;

  ProgressBarBackgroundColor: string = '#dee2e6';
  ProgressBarFillColor: string = '#40c057';

  ActionButtonBackgroundColor: string = colors.white;
  ActionButtonTextColor: string = colors.black;

  MapImageDataModelJson?: string;
  MapScaleToHeightEnabled: boolean;
  MapInitialScale: number;

  RulesContent: string;
  RulesContentStateJson: string;

  constructor(props?: Partial<TreasureHuntProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.CheckpointOrder = props.CheckpointOrder || '[]';

    this.ScoreEnabled = asBoolean(props.ScoreEnabled, true);
    this.DefaultPoints = asNumber(props.DefaultPoints, 1000);
    this.CheckpointsLogoEnabled = asBoolean(props.CheckpointsLogoEnabled, true);
    this.GameClosedTitleEnabled = asBoolean(props.GameClosedTitleEnabled, true);
    this.GameClosedDescriptionEnabled = asBoolean(
      props.GameClosedDescriptionEnabled,
      true
    );
    this.CheckpointsCoverEnabled = asBoolean(
      props.CheckpointsCoverEnabled,
      true
    );
    this.CheckpointsTitleEnabled = asBoolean(
      props.CheckpointsTitleEnabled,
      true
    );
    this.CheckpointsDescriptionEnabled = asBoolean(
      props.CheckpointsDescriptionEnabled,
      true
    );
    this.MapScaleToHeightEnabled = asBoolean(
      props.MapScaleToHeightEnabled,
      true
    );
    this.MapInitialScale = asFloat(props.MapInitialScale, 1);
  }

  get CheckpointOrderArray(): Guid[] {
    return parseCheckpointOrder(this.CheckpointOrder).reduce(
      (ids, checkpointId) => {
        const id = Guid.valueOrUndefined(checkpointId);
        if (id) {
          ids.push(id);
        }

        return ids;
      },
      []
    );
  }

  get MapImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.MapImageDataModelJson);
  }

  get CheckpointsLogoImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.CheckpointsLogoImageDataModelJson);
  }

  get CheckpointsCoverImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(
      this.CheckpointsCoverImageDataModelJson
    );
  }

  get CheckpointSubmitButtonDataModel(): ButtonDataModel {
    return ButtonDataModel.fromJsonOrNew(
      this.CheckpointSubmitButtonDataModelJson,
      {
        text: TreasureHuntProperties.SubmitButtonTextPlaceholder
      }
    );
  }

  get CheckpointDrawerProperties(): TreasureHuntDrawerProperties {
    return TreasureHuntDrawerProperties.fromJsonOrNew(
      this.CheckpointDrawerPropertiesJson
    );
  }

  get MapDrawerProperties(): TreasureHuntDrawerProperties {
    return TreasureHuntDrawerProperties.fromJsonOrNew(
      this.MapDrawerPropertiesJson,
      {
        bodyBackgroundColor: '#f1f3f5'
      }
    );
  }

  get RulesDrawerProperties(): TreasureHuntDrawerProperties {
    return TreasureHuntDrawerProperties.fromJsonOrNew(
      this.RulesDrawerPropertiesJson
    );
  }
}

const parseCheckpointOrder = (order: string): Guid[] => {
  try {
    return JSON.parse(order);
  } catch (e) {
    return [];
  }
};
